/* Footer.css */
p{
  text-align: center;
}
.footer-container {
  padding: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.social-link {
  color: #fff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-link:hover {
  color: #ddd;
}

.icon {
  font-size: 2rem;
}

.twitter {
  color: #1DA1F2;
}

.linkedin {
  color: #0077B5;
}

.github {
  color: #ffffff;
}

.email {
  color: #EA4335;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .icon {
    font-size: 1.5rem;
  }

  .social-links {
    gap: 15px;
  }
}

@media screen and (max-width: 480px) {
  .icon {
    font-size: 1.2rem;
  }

  .social-links {
    gap: 10px;
  }
}
