/* Home.css */
.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    text-align: center;
    background-color: #f0f0f0;
  }
  
  .profile-section {
    max-width: 800px;
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .profile-photo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .divider {
    width: 60%;
    margin: 20px auto;
    border: 1px solid #ddd;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.8;
    color: #666;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {

    .home-container {

      padding:10px;

    }
    .profile-section {
      padding: 20px;
    }
  
    .profile-photo {
      width: 100px;
      height: 100px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    p {
      font-size: 0.9rem;
    }
  }
  