/* Navbar.css */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .navbar {
    background: #333;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    
    z-index: 10;
  }

  /* Add to Navbar.css */
.navbar {
  position: sticky;
  top: 0;
  background-color: #333;
  z-index: 1000;
}

html {
  scroll-behavior: smooth;
}


  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    max-width: 1500px;
    padding: 0 24px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .menu-icon {
    display: none;
    color: #fff;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    transition: background 0.3s ease;
  }
  
  .nav-links:hover {
    background-color: #575757;
    border-radius: 4px;
  }
  
  /* Mobile styles */
  @media screen and (max-width: 960px) {
    .navbar-container {
      display: flex;
      justify-content: space-between;
    }
  
    .menu-icon {
      display: block;
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      background: #333;
      z-index: 1;
    }
  
    .nav-menu.active {
      left: 0;
    }
  
    .nav-item {
      height: 60px;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  }
  
  /* Desktop styles */
  @media screen and (min-width: 961px) {
    .navbar {
      background: linear-gradient(to right, #333, #444);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  
    .nav-links {
      padding: 0.5rem 2rem;
    }
  
    .nav-links:hover {
      background-color: transparent;
      color: #ff8c00;
      border-bottom: 2px solid #ff8c00;
      transition: border-bottom 0.3s ease;
    }
  }
  

  /* Add to relevant CSS files */
.navbar a, .project-item, .contact-link {
  transition: all 0.3s ease;
}
