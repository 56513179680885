/* Projects.css */
.projects-container {
    padding: 20px;
    background-color: #f0f0f0;
    text-align: center;
  }
  
  .projects-container h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .project-item {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: #333;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .project-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .project-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .project-item h3 {
    font-size: 1.2rem;
    margin: 0;
  }

/* Add to Projects.css */
.project-item {
    position: relative;
    overflow: hidden;
  }
  
  .project-item img {
    transition: transform 0.3s ease;
  }
  
  .project-item:hover img {
    transform: scale(1.1);
  }
  
  .project-item h3 {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
  

  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .projects-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .projects-container h2 {
      font-size: 2rem;
    }
  
    .project-item {
      padding: 15px;
    }
  
    .project-item h3 {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .projects-grid {
      grid-template-columns: 1fr;
    }
  }
  