/* Gallery.css */
.gallery-container {
    padding: 20px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
  
  .gallery-item img {
    width: 100%;
    height: auto;
    cursor: pointer;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .gallery-item img:hover {
    transform: scale(1.05);
  }
  
  /* Popup styles */
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .gallery-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  
    .popup-image {
      max-width: 90%;
      max-height: 90%;
    }
  
    .close {
      font-size: 25px;
    }
  }
  