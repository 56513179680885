

:root {
  --primary-color: #0077b5; /* LinkedIn blue */
  --secondary-color: #333;
  --background-color: #f0f0f0;
  --text-color: #333;
  --link-color: #1da1f2; /* Twitter blue */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}
